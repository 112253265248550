.post-item {
  padding: 18px 24px;
  border-radius: 10px;
  border: 1px solid rgba(51, 51, 51, 0.5);
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  &-left {
    flex: 1;
  }
}
