#app-layout-sider {
  height: 100%;
  .ant-menu-submenu-title {
    .ant-menu-title-content {
      a {
        color: rgba(255, 255, 255, 0.65);
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .logo {
    display: flex;
    justify-content: center;
    padding: 4;
    img {
      width: 68%;
      border-radius: 8px;
    }
  }
}

.my-menu {
  min-width: 260px !important;
}

.ant-layout-sider-collapsed {
  min-width: 80px !important;
}

#app-layout .ant-layout .ant-layout-sider-trigger {
  width: 260px !important;
}
