.ant-upload-wrapper {
  .ant-upload {
    text-align: unset;
    background: unset;
    border: unset;
    .ant-upload-btn {
      padding: 0;
      color: rgba(0, 0, 0, 0.3);
    }
  }
}
