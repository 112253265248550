.ant-table .ant-table-container {
  .ant-table-header table thead.ant-table-thead .ant-table-cell {
    background: #1890ff !important;
    color: #fff;
    padding: 7px 7px;
    text-align: left;
  }

  .ant-table-body table tbody .ant-table-cell {
    padding: 5px 7px !important;
    text-align: left;
    font-size: 13px;
  }
}

.mymodalTopic {
  .ant-modal-content {
    padding: 20px 0px 20px 24px !important;
  }
}

.text-esclipse {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-tab.active-tab .ant-tabs-tab-btn {
  background-color: red;
  color: white;
}
.btnAdd {
  margin-bottom: 15px;
  display: flex;
  justify-content: right;
}
