.containerCategory {
  .ant-collapse {
    background: #fff !important;
  }
  .ant-collapse-item {
    background: #fff;
  }
  .ant-collapse-content-box {
    padding: 0px !important;
  }
  .ant-collapse-header {
    display: flex;
    align-items: center !important;
    margin-left: 40px;
    height: 50px;
  }

  .collapseCategoryHeader {
    .ant-collapse-item {
      border-bottom: 0px solid #fff;
    }
  }

  .collapseCategory {
    .ant-collapse-header {
      display: flex;
      align-items: center !important;
      margin-left: 260px;
    }
  }

  .globalText {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: #000;
  }

  .globalTextNormal {
    font-weight: 400;
  }

  .header {
    background: #f0f0f0;
    border: 1px #dddddd solid;
    border-bottom: 0px #dddddd solid;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .cellCategory {
    height: 50px;
    display: flex;
    align-content: center;
    cursor: pointer;
  }

  .borderBot {
    // border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }

  .contentContainer {
    background: #fff;
    border: 1px #dddddd solid;
    border-top: 0px #dddddd solid;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin-left: 15px;
    margin-right: 5px;
    margin-top: 50px;
  }
}
