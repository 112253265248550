.text-esclipse {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mymodal {
  .ant-modal-content {
    padding: 20px 0px 20px 24px;
  }
}
