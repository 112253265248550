$medium: 1281px;

#login-page {
  background-color: #fafafa;
  background-image: url('../../../../../public/login.png');
  background-repeat: round;
  height: 100vh;
  padding: 160px 236px 267px 81px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $medium) {
    padding: 60px 236px 267px 81px;
  }
  .logo {
    margin-top: -115px;
    @media screen and (max-width: $medium) {
      margin-top: -75px;
      svg {
        width: 280px;
      }
    }
  }
  .login-content {
    width: 554px;
    height: 587px;
    padding: 63px 41px;
    border: 1px solid #ffa500;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
      0 9px 28px 8px rgb(0 0 0 / 5%);
    @media screen and (max-width: $medium) {
      width: 500px;
      height: 550px;
    }
    .label {
      font-size: 20px;
      color: #586875;
      font-weight: 600;
      margin-bottom: 14px;
      margin-top: 13px;
    }
    #login-form {
      margin-top: 1.6rem;
      .submit-btn {
        display: flex;
        justify-content: center;
      }
    }
  }
}
