// table

.ant-table-thead > tr > th {
  padding: 10px 16px;
  font-size: 14px;
}

.ant-table-tbody {
  > tr > td {
    padding: 0.7vh 8px;
    font-size: 14px;
  }
}

.ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell {
  background: #1890ff !important;
  color: #fff;
}

.box-actions {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  justify-content: space-between;
  .box-search {
    width: 25rem;
  }
}

.center-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

