.tagContainer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 20px;
  justify-content: space-between;
  position: relative;
}

.btnChange {
  border-width: 0;
  background-color: transparent;
  box-shadow: none;
}

.optionsContainer {
  display: none;
  flex-direction: column;
  background-color: red;
}

.tag {
  position: relative;
}

.btnChange:hover {
  .optionsContainer {
    display: flex;
    position: absolute;
  }
  .tag {
    background-color: pink;
  }
}
