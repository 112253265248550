.header {
  position: absolute;
  z-index: 2;
  width: calc(100% - 300px);
  background: #f5f5f5;

  @media screen and (max-width: 768px) {
    width: 95%;
  }
}

.addNewContainer {
  height: calc(100vh - 70px);
  overflow: auto;
  // max-height: calc(100% - 1000px);
}

.postContainer {
  .ck {
    .ck-sticky-panel__content_sticky {
      box-shadow: none !important;
      padding-top: 40px;
    }
  }
}
