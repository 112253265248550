.mymodalPosts {
  .ant-modal-content {
    padding-right: 1px !important;
    padding-left: 1px !important;
  }
}
.myTablePost {
  .ant-table-container {
    .ant-table-header table thead.ant-table-thead .ant-table-cell {
      background: #1890ff !important;
      color: #fff;
      padding: 7px 7px;
      text-align: left;
    }

    .ant-table-body table tbody .ant-table-cell {
      padding: 5px 7px !important;
      text-align: left;
      font-size: 13px;
    }
  }
}
