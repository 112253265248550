#app-layout {
  .ant-layout {
    height: 100vh;
    overflow: hidden;
    .ant-layout-sider {
      .ant-layout-sider-children {
        height: 100%;
        overflow: auto;
      }
    }
    .ant-layout-sider-trigger {
      width: 220px !important;
    }
    .ant-layout-sider-collapsed {
      .ant-layout-sider-trigger {
        width: 80px !important;
      }
    }
    .ant-layout-content {
      overflow: auto;
      height: 100vh;
      .app-layout-content {
        // margin-top: var(--default-layout-header-height);
        padding: 10px 10px;
      }
    }
  }
}
