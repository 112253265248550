#header {
  display: flex;
  width: 100%;
  height: var(--default-layout-header-height);
  background: #fff;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  .options {
  }
}
